<template>
    <div class="main-content">
        <div class="breadcrumb">
            <Breadcrumb>
                <BreadcrumbItem>统计报表</BreadcrumbItem>
                <BreadcrumbItem>职位交付</BreadcrumbItem>
            </Breadcrumb>
        </div>
        <div class="filter">
            <Form label-colon inline>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.myDateRange" placeholder="请选择更新周期" separator=" 至 "></DatePicker>
                </FormItem>
                <FormItem>
                    <DatePicker type="daterange" v-model="filter.createDate" placeholder="请选择创建周期" separator=" 至 "></DatePicker>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.userId" placeholder="请选择顾问" filterable clearable>
                        <Option v-for="item in userArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.createId" placeholder="请选择创建人" filterable clearable>
                        <Option v-for="item in userArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <deptTreeSelect v-model="filter.departmentId" :options="departmentOpts" placeholder="请选择所属组织" noOptionsText="暂无数据" noResultsText="没有匹配数据" clearable />
                </FormItem>
                <FormItem>
                    <Select v-model="filter.customerId" placeholder="请选择客户名称" filterable clearable>
                        <Option v-for="item in customerArray" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.source"  placeholder="请选择职位来源" filterable clearable label-in-value>
                        <Option v-for="item in dict.customer_source" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.type" placeholder="请选择职位类型" filterable clearable>
                        <Option v-for="item in dict.position_type" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.level" placeholder="请选择职位级别" filterable clearable>
                        <Option v-for="item in dict.position_level" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Select v-model="filter.status" placeholder="请选择职位状态" filterable clearable>
                        <Option v-for="item in dict.position_status" :value="item.id" :key="item.id">{{ item.name }}</Option>
                    </Select>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="doSearching">搜索</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" @click="resetting">重置</Button>
                </FormItem>
                <FormItem>
                    <Button type="primary" icon="md-cloud-download" @click="handleExport" v-if="permissionButton.export">导出</Button>
                </FormItem>
            </Form>
        </div>
        <div class="table">
            <div class="table-list">
                <Table border stripe :columns="columns" :data="dataList" :loading="loading"></Table>
            </div>
            <div class="table-footer">
                <Row>
                    <Col class="total" span="6">共 {{ total }} 条记录 第 {{ pageNum }}/{{ pages }} 页</Col>
                    <Col class="pagenation" span="18">
                        <Page :page-size="pageSize" :total="total" :current="pageNum"
                              :page-size-opts="[15, 20, 50, 100]"
                              @on-change="changePage" @on-page-size-change="changPageSize" show-sizer show-elevator/>
                    </Col>
                </Row>
            </div>
        </div>
    </div>
</template>

<script>
import permission from '@/common/permission'
import {reqDictionaryMapByCodeList, reqGetUserList} from "../../../api/system-api";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import deptTreeSelect from "@riophae/vue-treeselect";
import {
    reqGetDepartmentTreeOpts,
    reqGetCustomerPerUser,
    reqGetPositionReport,
    reqExportPositionReport,
    reqGetAllCustomer
} from "../../../api/admin-api";

export default {
    name: 'positionReport',
    components: {deptTreeSelect},
    data() {
        return {
            filter: {
                myDateRange: null,
                createDate: null,
                userId: null,
                departmentId: null,
                customerId: null,
                createId: null,
                type: null,
                source: null,
                level: null,
                status: null,
            },
            countsOfRecommendI: 0,
            countsOfBookingI: 0,
            countsOfPresentI: 0,
            countsOfReceiveI: 0,
            performanceI: 0,
            countsOfRecommendE: 0,
            countsOfFirstRoundE: 0,
            countsOfSecondRoundE: 0,
            countsOfOfferE: 0,
            countsOfEntryE: 0,
            performanceE: 0,
            balPerformanceE: 0,
            handoverQuantityR: 0,
            costR: 0,
            performanceR: 0,
            balPerformanceR: 0,
            dict: {},
            userArray: [],
            departmentOpts: [],
            customerArray: [],
            loading: false,
            dataList: [],
            permissionButton: {
                export: false,
            },
            total: 0,
            pages: 1,
            pageSize: 20,
            pageNum: 1,
            columns: [
                {
                    title: '顾问',
                    key: 'consultantName',
                    width: 120,
                    fixed: 'left',
                },
                {
                    title: '所属组织',
                    key: 'departmentName',
                    tooltip: 'true',
                    width: 150,
                    fixed: 'left',
                },
                {
                    title: '职位名称',
                    key: 'name',
                    tooltip: 'true',
                    width: 180,
                    fixed: 'left',
                },
                {
                    title: '职位创建时间',
                    key: 'createTime',
                    align: 'center',
                    width: 160,
                    fixed: 'left',
                },
                {
                    title: '客户名称',
                    key: 'customerName',
                    tooltip: 'true',
                    minWidth: 200,
                    fixed: 'left',
                },
                {
                    title: '职位来源',
                    key: 'sourceName',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '职位状态',
                    key: 'statusName',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '职位级别',
                    key: 'levelName',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '职位类型',
                    key: 'typeName',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '面试',
                    align: 'center',
                    children: [{
                        key: 'countsOfRecommendI',
                        align: 'center',
                        minWidth: 120,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfRecommendI ? params.row.countsOfRecommendI : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "推荐(" + this.countsOfRecommendI + ")")
                        }
                    }, {
                        key: 'countsOfBookingI',
                        align: 'center',
                        minWidth: 100,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfBookingI ? params.row.countsOfBookingI : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "约面(" + this.countsOfBookingI + ")")
                        }
                    }, {
                        key: 'countsOfPresentI',
                        align: 'center',
                        minWidth: 100,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfPresentI ? params.row.countsOfPresentI : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "到场(" + this.countsOfPresentI + ")")
                        }
                    }, {
                        key: 'countsOfReceiveI',
                        align: 'center',
                        minWidth: 100,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfReceiveI ? params.row.countsOfReceiveI : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "回款(" + this.countsOfReceiveI + ")")
                        }
                    }, {
                        key: 'performanceI',
                        align: 'center',
                        minWidth: 180,
                        render: (h, params) => {
                            return h('span' , (params.row.performanceI ? params.row.performanceI.toFixed(2) : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "交付金额(" + this.performanceI.toFixed(2) + ")")
                        }
                    }]
                },
                {
                    title: '入职',
                    align: 'center',
                    children: [{
                        key: 'countsOfRecommendE',
                        align: 'center',
                        minWidth: 120,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfRecommendE ? params.row.countsOfRecommendE : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "推荐(" + this.countsOfRecommendE + ")")
                        }
                    }, {
                        key: 'countsOfFirstRoundE',
                        align: 'center',
                        minWidth: 100,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfFirstRoundE ? params.row.countsOfFirstRoundE : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "初试(" + this.countsOfFirstRoundE + ")")
                        }
                    }, {
                        key: 'countsOfSecondRoundE',
                        align: 'center',
                        minWidth: 100,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfSecondRoundE ? params.row.countsOfSecondRoundE : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "复试(" + this.countsOfSecondRoundE + ")")
                        }
                    }, {
                        key: 'countsOfOfferE',
                        align: 'center',
                        minWidth: 100,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfOfferE ? params.row.countsOfOfferE : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "Offer(" + this.countsOfOfferE + ")")
                        }
                    }, {
                        key: 'countsOfEntryE',
                        align: 'center',
                        minWidth: 100,
                        render: (h, params) => {
                            return h('span' , (params.row.countsOfEntryE ? params.row.countsOfEntryE : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "入职(" + this.countsOfEntryE + ")")
                        }
                    }, {
                        key: 'performanceE',
                        align: 'center',
                        minWidth: 180,
                        render: (h, params) => {
                            return h('span' , (params.row.performanceE ? params.row.performanceE.toFixed(2) : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "首款金额(" + this.performanceE.toFixed(2) + ")")
                        }
                    }, {
                        key: 'balPerformanceE',
                        align: 'center',
                        minWidth: 180,
                        render: (h, params) => {
                            return h('span' , (params.row.balPerformanceE ? params.row.balPerformanceE.toFixed(2) : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "尾款金额(" + this.balPerformanceE.toFixed(2) + ")")
                        }
                    }]
                },
                {
                    title: 'RPO/其他',
                    align: 'center',
                    children: [{
                        key: 'handoverQuantityR',
                        align: 'center',
                        minWidth: 180,
                        render: (h, params) => {
                            return h('span' , (params.row.handoverQuantityR ? params.row.handoverQuantityR : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "交付人数(" + this.handoverQuantityR + ")")
                        }
                    }, {
                        key: 'costR',
                        align: 'center',
                        minWidth: 180,
                        render: (h, params) => {
                            return h('span' , (params.row.costR ? params.row.costR.toFixed(2) : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "成本金额(" + this.costR.toFixed(2) + ")")
                        }
                    }, {
                        key: 'performanceR',
                        align: 'center',
                        minWidth: 180,
                        render: (h, params) => {
                            return h('span' , (params.row.performanceR ? params.row.performanceR.toFixed(2) : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "首款金额(" + this.performanceR.toFixed(2) + ")")
                        }
                    }, {
                        key: 'balPerformanceR',
                        align: 'center',
                        minWidth: 180,
                        render: (h, params) => {
                            return h('span' , (params.row.balPerformanceR ? params.row.balPerformanceR.toFixed(2) : 0))
                        },
                        renderHeader: (h , params) => {
                            return h('span' , "尾款金额(" + this.balPerformanceR.toFixed(2) + ")")
                        }
                    }]
                }
            ],
        }
    },
    methods: {
        init() {
            // 用户列表
            reqGetUserList().then((res) => {
                this.userArray = res.data;
            }).catch(() => {
                this.userArray = [];
            })

            // 所属组织
            reqGetDepartmentTreeOpts().then(res => {
                this.departmentOpts = res.data.data;
            }).catch(()=>{
                this.departmentOpts = [];
            })

            // 客户列表
            reqGetAllCustomer().then((res) => {
                this.customerArray = res.data;
            }).catch(() => {
                this.customerArray = []
            })

            let codes = ['customer_source', 'position_type', 'position_level', 'position_status'];
            reqDictionaryMapByCodeList(codes).then((res) => {
                this.dict = res.data.data;
            }).catch(() => {
                this.dict = {};
            })
        },
        //分页点击，切换页面
        changePage(val) {
            this.pageNum = val;
            this.getPositionReport();
        },
        //每页显示的数据条数
        changPageSize(numOfRows) {
            //实时获取当前需要显示的条数
            this.pageSize = numOfRows;
            this.getPositionReport();
        },
        doSearching() {
            this.pageNum = 1;
            this.getPositionReport();
        },
        getPositionReport() {
            this.loading = true;
            this.dataList = [];
            let startDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 0 && this.filter.myDateRange[0]) ? this.$moment(this.filter.myDateRange[0]).format('YYYY-MM-DD') : null);
            let endDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 1 && this.filter.myDateRange[1]) ? this.$moment(this.filter.myDateRange[1]).format('YYYY-MM-DD') : null);
            let createStartDate = ((this.filter.createDate && this.filter.createDate.length > 0 && this.filter.createDate[0]) ? this.$moment(this.filter.createDate[0]).format('YYYY-MM-DD') : null);
            let createEndDate = ((this.filter.createDate && this.filter.createDate.length > 1 && this.filter.createDate[1]) ? this.$moment(this.filter.createDate[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                startDate: startDate,
                endDate: endDate,
                createStartDate: createStartDate,
                createEndDate: createEndDate,
            }, this.filter);

            this.countsOfRecommendI= 0
            this.countsOfBookingI= 0
            this.countsOfPresentI= 0
            this.countsOfReceiveI= 0
            this.performanceI= 0
            this.countsOfRecommendE= 0
            this.countsOfFirstRoundE= 0
            this.countsOfSecondRoundE= 0
            this.countsOfOfferE= 0
            this.countsOfEntryE= 0
            this.performanceE= 0
            this.balPerformanceE= 0
            this.handoverQuantityR= 0
            this.costR= 0
            this.performanceR= 0
            this.balPerformanceR= 0
            reqGetPositionReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total

                for (let i = 0; i < res.data.data.records.length; i++) {
                    this.countsOfRecommendI += (res.data.data.records[i].countsOfRecommendI ? res.data.data.records[i].countsOfRecommendI : 0);
                    this.countsOfBookingI += (res.data.data.records[i].countsOfBookingI ? res.data.data.records[i].countsOfBookingI : 0);
                    this.countsOfPresentI += (res.data.data.records[i].countsOfPresentI ? res.data.data.records[i].countsOfPresentI : 0);
                    this.countsOfReceiveI += (res.data.data.records[i].countsOfReceiveI ? res.data.data.records[i].countsOfReceiveI : 0);
                    this.performanceI += (res.data.data.records[i].performanceI ? res.data.data.records[i].performanceI : 0);
                    this.countsOfRecommendE += (res.data.data.records[i].countsOfRecommendE ? res.data.data.records[i].countsOfRecommendE : 0);
                    this.countsOfFirstRoundE += (res.data.data.records[i].countsOfFirstRoundE ? res.data.data.records[i].countsOfFirstRoundE : 0);
                    this.countsOfSecondRoundE += (res.data.data.records[i].countsOfSecondRoundE ? res.data.data.records[i].countsOfSecondRoundE : 0);
                    this.countsOfOfferE += (res.data.data.records[i].countsOfOfferE ? res.data.data.records[i].countsOfOfferE : 0);
                    this.countsOfEntryE += (res.data.data.records[i].countsOfEntryE ? res.data.data.records[i].countsOfEntryE : 0);
                    this.performanceE += (res.data.data.records[i].performanceE ? res.data.data.records[i].performanceE : 0);
                    this.balPerformanceE += (res.data.data.records[i].balPerformanceE ? res.data.data.records[i].balPerformanceE : 0);
                    this.handoverQuantityR += (res.data.data.records[i].handoverQuantityR ? res.data.data.records[i].handoverQuantityR : 0);
                    this.costR += (res.data.data.records[i].costR ? res.data.data.records[i].costR : 0);
                    this.performanceR += (res.data.data.records[i].performanceR ? res.data.data.records[i].performanceR : 0);
                    this.balPerformanceR += (res.data.data.records[i].balPerformanceR ? res.data.data.records[i].balPerformanceR : 0);
                }
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        resetting() {
            this.loading = true;
            this.dataList = [];

            this.filter.myDateRange= null;
            this.filter.createDate= null;
            this.filter.userId= null;
            this.filter.departmentId= null;
            this.filter.customerId= null;
            this.filter.createId= null;
            this.filter.type= null;
            this.filter.source= null;
            this.filter.level= null;
            this.filter.status= null;

            let startDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 0 && this.filter.myDateRange[0]) ? this.$moment(this.filter.myDateRange[0]).format('YYYY-MM-DD') : null);
            let endDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 1 && this.filter.myDateRange[1]) ? this.$moment(this.filter.myDateRange[1]).format('YYYY-MM-DD') : null);
            let createStartDate = ((this.filter.createDate && this.filter.createDate.length > 0 && this.filter.createDate[0]) ? this.$moment(this.filter.createDate[0]).format('YYYY-MM-DD') : null);
            let createEndDate = ((this.filter.createDate && this.filter.createDate.length > 1 && this.filter.createDate[1]) ? this.$moment(this.filter.createDate[1]).format('YYYY-MM-DD') : null);
            let params = Object.assign({
                pageNum: 1,
                pageSize: 20,
                startDate: startDate,
                endDate: endDate,
                createStartDate: createStartDate,
                createEndDate: createEndDate,
            }, this.filter);

            this.countsOfRecommendI= 0
            this.countsOfBookingI= 0
            this.countsOfPresentI= 0
            this.countsOfReceiveI= 0
            this.performanceI= 0
            this.countsOfRecommendE= 0
            this.countsOfFirstRoundE= 0
            this.countsOfSecondRoundE= 0
            this.countsOfOfferE= 0
            this.countsOfEntryE= 0
            this.performanceE= 0
            this.balPerformanceE= 0
            this.handoverQuantityR= 0
            this.costR= 0
            this.performanceR= 0
            this.balPerformanceR= 0
            reqGetPositionReport(params).then((res) => {
                this.loading = false;
                this.pageNum = res.data.data.current
                this.pages = res.data.data.pages
                this.total = res.data.data.total

                for (let i = 0; i < res.data.data.records.length; i++) {
                    this.countsOfRecommendI += (res.data.data.records[i].countsOfRecommendI ? res.data.data.records[i].countsOfRecommendI : 0);
                    this.countsOfBookingI += (res.data.data.records[i].countsOfBookingI ? res.data.data.records[i].countsOfBookingI : 0);
                    this.countsOfPresentI += (res.data.data.records[i].countsOfPresentI ? res.data.data.records[i].countsOfPresentI : 0);
                    this.countsOfReceiveI += (res.data.data.records[i].countsOfReceiveI ? res.data.data.records[i].countsOfReceiveI : 0);
                    this.performanceI += (res.data.data.records[i].performanceI ? res.data.data.records[i].performanceI : 0);
                    this.countsOfRecommendE += (res.data.data.records[i].countsOfRecommendE ? res.data.data.records[i].countsOfRecommendE : 0);
                    this.countsOfFirstRoundE += (res.data.data.records[i].countsOfFirstRoundE ? res.data.data.records[i].countsOfFirstRoundE : 0);
                    this.countsOfSecondRoundE += (res.data.data.records[i].countsOfSecondRoundE ? res.data.data.records[i].countsOfSecondRoundE : 0);
                    this.countsOfOfferE += (res.data.data.records[i].countsOfOfferE ? res.data.data.records[i].countsOfOfferE : 0);
                    this.countsOfEntryE += (res.data.data.records[i].countsOfEntryE ? res.data.data.records[i].countsOfEntryE : 0);
                    this.performanceE += (res.data.data.records[i].performanceE ? res.data.data.records[i].performanceE : 0);
                    this.balPerformanceE += (res.data.data.records[i].balPerformanceE ? res.data.data.records[i].balPerformanceE : 0);
                    this.handoverQuantityR += (res.data.data.records[i].handoverQuantityR ? res.data.data.records[i].handoverQuantityR : 0);
                    this.costR += (res.data.data.records[i].costR ? res.data.data.records[i].costR : 0);
                    this.performanceR += (res.data.data.records[i].performanceR ? res.data.data.records[i].performanceR : 0);
                    this.balPerformanceR += (res.data.data.records[i].balPerformanceR ? res.data.data.records[i].balPerformanceR : 0);
                }
                this.dataList = res.data.data.records;
            }).catch(() => {
                this.loading = false;
                this.dataList = [];
            });
        },
        handleExport() {
            this.$Modal.confirm({
                title: '导出职位交付',
                content: '确定导出职位交付查询结果？',
                width: '320',
                onOk: () => {
                    let startDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 0 && this.filter.myDateRange[0]) ? this.$moment(this.filter.myDateRange[0]).format('YYYY-MM-DD') : null);
                    let endDate = ((this.filter.myDateRange && this.filter.myDateRange.length > 1 && this.filter.myDateRange[1]) ? this.$moment(this.filter.myDateRange[1]).format('YYYY-MM-DD') : null);
                    let createStartDate = ((this.filter.createDate && this.filter.createDate.length > 0 && this.filter.createDate[0]) ? this.$moment(this.filter.createDate[0]).format('YYYY-MM-DD') : null);
                    let createEndDate = ((this.filter.createDate && this.filter.createDate.length > 1 && this.filter.createDate[1]) ? this.$moment(this.filter.createDate[1]).format('YYYY-MM-DD') : null);
                    this.filter.startDate = startDate;
                    this.filter.endDate = endDate;
                    this.filter.createStartDate= createStartDate;
                    this.filter.createEndDate= createEndDate;
                    reqExportPositionReport(this.filter).then((res)=>{
                        let filename = 'Position.xlsx';
                        const disposition = res.headers['content-disposition'];
                        if (typeof disposition !== 'undefined') {
                            let deDisposition = decodeURIComponent(disposition);
                            filename = deDisposition.substring(deDisposition.indexOf('filename=') + 9, deDisposition.length);
                        }

                        let blob = new Blob([res.data], {type: 'application/vnd.ms-excel;charset=utf-8'}) //type这里表示xlsx类型
                        let downloadElement = document.createElement('a')
                        let href = window.URL.createObjectURL(blob) //创建下载的链接
                        downloadElement.href = href
                        downloadElement.download = filename //下载后文件名
                        document.body.appendChild(downloadElement)
                        downloadElement.click() //点击下载
                        document.body.removeChild(downloadElement) //下载完成移除元素
                        window.URL.revokeObjectURL(href) //释放掉blob对象
                    }).catch((err)=>{
                        this.$Message.error('导出失败')
                    })
                },
            })
        }
    },
    mounted() {
        let permissionId = this.$route.query.permissionId;
        permission.getPermissionButton(permissionId, this.permissionButton);
        this.init();
        this.getPositionReport();
    }
}
</script>
